import style from './ContactUs.module.scss';
import axios from 'axios';

import 'swiper/css';
import { useState,useEffect } from 'react';
import 'react-phone-number-input/style.css'
import { useForm, Controller } from "react-hook-form";

import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
const ContactUs = () => {
  const [mailAddress, setMailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [purchasingBudget, setPurchasingBudget] = useState("");
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false)



  useEffect(()=> {
    let elem = document.getElementById("root")
    if (elem) {
        elem.scrollIntoView({behavior: "auto"})
    }
}, [])
const {
  control,
  handleSubmit,
  formState: { errors },
} = useForm();

console.log("phoneNumber",phoneNumber)

async function sendContactUs(event){
  setShowLoadingModal(true)
  const body = {
    "firstName": firstName ,
    "lastName" : lastName,
    "country" : country,
    "mailAddress" : mailAddress,
    "contactNumber" : phoneNumber,
    "walletAddress" : walletAddress,
    "purchasingBudget" : purchasingBudget
  }
  axios.post('https://anarchycoin.api.dapprex.net/contact-us', body)
  .then(function (response) {
    console.log(response);
    setIsSubscribe(true)
    setShowLoadingModal(false)
  })
  .catch(function (error) {
    console.log(error);
    setIsSubscribe(true)
    setShowLoadingModal(false)
  });
}




  return (
    <>
     {showLoadingModal && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: "none" }}>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setShowLoadingModal(false)
                  }}
                />
              </div>
              <div className="modal-body">
                <h4 className="text-center">
                  Submitting...
                </h4>

                <div style={{ marginBottom: "40px", marginTop: "28px", fontSize : "60px" }} className='text-center'>
                <i class="fas fa-spinner fa-pulse"></i>
                  {/* <CircularLoading /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
       <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="pho">Contact Us</h1>
          </div>
          <div className="col-md-6 pbgel">
            <img src="./assets/pbgel.svg" />
          </div>
        </div>
        {!!isSubscribe ?
        <div className='thanks-div'>
        <h4> 
          Thanks for submitting the form.
          </h4>
         </div>
        :
        <> 
         <div>
       <h4> Leave your details below and we’ll get in touch with you regarding your Anarchy purchase inquiry.</h4>
        </div>
        <div  className='container contact-us-div'>
          <div className='row'>
            <div style={INPUTDIV} className="contact-us-inpdiv ">
            <form onSubmit={handleSubmit((data)=>{sendContactUs(data);  data.preventDefault();})} >
           <div className='row'>
           <div className='col-12 col-md-4'>
            <input
                required={true}
                style={INPUT}
                type="text"
                placeholder="First Name"
                id="First-Name"
                name="First-Name" 
                onChange={(event)=>setFirstName(event.target.value)}
              />
            </div>
            <div className='col-12 col-md-4'>
            <input
                required={true}
                style={INPUT}
                type="text"
                placeholder="Last Name"
                id="Last-Name"
                name="Last-Name" 
                onChange={(event)=>setLastName(event.target.value)}

              />
            </div>
            <div className='col-12 col-md-4'>
            <input
                required={true}
                style={INPUT}
                type="text"
                placeholder="Country"
                id="country"
                name="country" 
                onChange={(event)=>setCountry(event.target.value)}

              />
            </div>
           </div>

           <div className='row'>
           <div className='col-12 col-md-6'>
            <input
                required={true}
                style={INPUT}
                type="mail"
                placeholder="Email Address"
                id="mail"
                name="mail" 
                onChange={(event)=>setMailAddress(event.target.value)}

              />
            </div>
            <div className='col-12 col-md-6'>
          

<Controller
          name="phone-input"
          control={control}
    
          rules={{
            required: true,
            validate: (value) => isValidPhoneNumber(value),
            onChange:(event) => setPhoneNumber(String(event.target.value))
          }}
          
          render={({ field: { onChange, value } }) => (
            <PhoneInput
                            
            style={INPUT_PHONE}
            error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
            placeholder="Enter phone number"
            value={value}
            id="phone-input"
            onChange={onChange} />
          )}
        />
        {errors["phone-input"] && (
          <p className="error-message">Invalid Phone</p>
        )}
          {/*   <input
                required={true}
                style={INPUT}
                type="tel"
                placeholder="Contact Number"
                minLength={11}
                maxLength={15}
                id="tel"
                name="tel" 
                onChange={(event)=>setPhoneNumber(event.target.value)}

              /> */}
            </div>
           </div>


        {/*    <div className='row'>
           <div className='col-12'>
            <input
                required={true}
                style={INPUT}
                type="text"
                placeholder="Wallet Address"
                id="wallet-address"
                name="wallet-address" 
                onChange={(event)=>setWalletAddress(event.target.value)}

              />
            </div>
           </div>    */}

            <div className='row'>
           <div className='col-12'>
            <input
                required={true}
                style={INPUT}
                type="number"
                placeholder="Purchasing Budget"
                id="Purchasing-Budget"
                name="Purchasing-Budget" 
                onChange={(event)=>setPurchasingBudget(event.target.value)}
                
              />
            </div>
           </div>              

           <div class="col-12 d-flex justify-content-center mt-4">
           <input style={BUTTON} type="submit" value="Submit"></input>

      </div>
</form>



{/*  */}
            </div>
          </div>
        </div>
        </>
        }
       
      </div>
    </>
  );
};


const INPUTDIV = {
  position : "relative"
}
const INPUT = {
  "fontFamily":"\"DM Sans\"",
  "fontStyle":"normal",
  "fontWeight":"400",
  "fontSize":"14px",
  "lineHeight":"100%",
  "color":"#CDCDCD",
  "border":"1px solid #FFFFFF",
  "borderRadius":"10px",
  "padding":"10px 20px",
  "backgroundColor":"rgba(0, 0, 0, 0)",
  "width":"100%",
  "marginTop":"10px",
  "height":"52px"
}

const INPUT_PHONE = {
  "fontFamily":"\"DM Sans\"",
  "fontStyle":"normal",
  "fontWeight":"400",
  "fontSize":"14px",
  "lineHeight":"100%",
  "color":"#CDCDCD",
  "border":"1px solid #FFFFFF",
  "borderRadius":"10px",
  "padding":"10px 20px",
  "backgroundColor":"rgba(0, 0, 0, 0)",
  "width":"100%",
  "marginTop":"10px",
  "height":"52px"
}

const BUTTON = {
  height: "44px",
  background: "#FFFFFF",
  fontFamily: '"DM Sans"',
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "100%",
  textTransform: ["uppercase", "uppercase"],
  color: "#080808",
  border: "1px solid #FFFFFF",
  borderRadius: "8px",
  paddingLeft: "15px",
  paddingRight: "15px"
}
export default ContactUs;
